import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function DeleteStatusConfirmDialog({
  onClose,
  open,
  onClickDeleteStatus,
}) {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Supprimer un status:</DialogTitle>
      <DialogContent>voulez vous vraiment supprimer ce status ?</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>Annuler</Button>
        <Button variant='contained' onClick={onClickDeleteStatus}>Supprimer</Button>
      </DialogActions>
    </Dialog>
  );
}
