import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userSelector, setUser } from './store/userSlice';
import { ContextProvider } from './context';

export default function AdminPage({page}) {
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const localStorageUser = localStorage.getItem('user');

    if (!user && localStorageUser) {
      dispatch(setUser(JSON.parse(localStorageUser)));
    } else if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);
  return (
    <ContextProvider>
      {page}
    </ContextProvider>
  );
}
