import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
  CarteGrise, Cession, ChangementStatut, CreationAssociation, CreationAutoEntreprise, CreationEntreprise, CreationStatut,
  Dissolution
} from '../products';

export default function DetailsProductDialog({
  onClose,
  open,
  produit,
  idProduit,
  product_id,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  const getContent = (typeProduct) => {
    switch (typeProduct) {
    case 'CS':
      return (
        <CreationStatut
          produit={produit}
          idProduit={idProduit}
          onClickSaveStatus={onClickSaveStatus}
          onClickDeleteStatus={onClickDeleteStatus}
          onClickSaveDocument={onClickSaveDocument}
          onClickDeleteDocument={onClickDeleteDocument}
        />);
    case 'CE':
      return (
        <CreationEntreprise
          produit={produit}
          idProduit={idProduit}
          onClickSaveStatus={onClickSaveStatus}
          onClickDeleteStatus={onClickDeleteStatus}
          onClickSaveDocument={onClickSaveDocument}
          onClickDeleteDocument={onClickDeleteDocument}
        />);
    case 'CA':
      return (
        <CreationAssociation
          produit={produit}
          idProduit={idProduit}
          onClickSaveStatus={onClickSaveStatus}
          onClickDeleteStatus={onClickDeleteStatus}
          onClickSaveDocument={onClickSaveDocument}
          onClickDeleteDocument={onClickDeleteDocument}
        />);
    case 'CAE':
      return (
        <CreationAutoEntreprise
          produit={produit}
          idProduit={idProduit}
          onClickSaveStatus={onClickSaveStatus}
          onClickDeleteStatus={onClickDeleteStatus}
          onClickSaveDocument={onClickSaveDocument}
          onClickDeleteDocument={onClickDeleteDocument}
        />);
    case 'CG':
      return (
        <CarteGrise
          produit={produit}
          idProduit={idProduit}
          onClickSaveStatus={onClickSaveStatus}
          onClickDeleteStatus={onClickDeleteStatus}
          onClickSaveDocument={onClickSaveDocument}
          onClickDeleteDocument={onClickDeleteDocument}
        />);
    case 'C':
      return (
        <Cession
          produit={produit}
          idProduit={idProduit}
          onClickSaveStatus={onClickSaveStatus}
          onClickDeleteStatus={onClickDeleteStatus}
          onClickSaveDocument={onClickSaveDocument}
          onClickDeleteDocument={onClickDeleteDocument}
        />);
    case 'D':
      return (
        <Dissolution
          produit={produit}
          idProduit={idProduit}
          onClickSaveStatus={onClickSaveStatus}
          onClickDeleteStatus={onClickDeleteStatus}
          onClickSaveDocument={onClickSaveDocument}
          onClickDeleteDocument={onClickDeleteDocument}
        />);
    case 'CHS':
      return (
        <ChangementStatut
          produit={produit}
          idProduit={idProduit}
          onClickSaveStatus={onClickSaveStatus}
          onClickDeleteStatus={onClickDeleteStatus}
          onClickSaveDocument={onClickSaveDocument}
          onClickDeleteDocument={onClickDeleteDocument}
        />);
    default:
      break;
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Détails du produit:</DialogTitle>
      <DialogContent>
        {getContent(product_id.split('-')[0])}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>Retour</Button>
      </DialogActions>
    </Dialog>
  );
}
