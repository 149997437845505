import React from 'react';
import AdminDashboard from '../../dashboard';
import AdminClients from '../../clients';
import AdminProfile from '../../profile';
import AdminPacks from '../../packs';
import AdminStatus from '../../status';
import AdminManagers from '../../managers';

function AdminContent({ activeTab }) {
  return (
    <div id='mainbody' className="flex-1 px-10 max-h-screen overflow-y-auto">
      {activeTab === 'dashboard' && <AdminDashboard/>}
      {activeTab === 'clients' && <AdminClients />}
      {activeTab === 'managers' && <AdminManagers />}
      {activeTab === 'packs' && <AdminPacks />}
      {activeTab === 'status' && <AdminStatus />}
      {activeTab === 'profile' && <AdminProfile />}
    </div>
  );
}

export default AdminContent;