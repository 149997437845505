import React, { createContext, useState } from 'react';

// Création du contexte
const AdminContext = createContext();

// Création du provider du contexte
export const ContextProvider = ({ children }) => {
  const colors = ['#3498db', '#2ecc71', '#e74c3c', '#9b59b6'];
  
  const [context, setContext] = useState({
    avatarColor: colors[Math.floor(Math.random() * colors.length)]
  });

  return (
    <AdminContext.Provider value={{ context, setContext }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;