import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function DeleteManagerConfirmDialog({
  onClose,
  open,
  onClickDeleteManager,
}) {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Supprimer un géstionnaire:</DialogTitle>
      <DialogContent>voulez vous vraiment supprimer ce géstionnaire ?</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>Annuler</Button>
        <Button variant='contained' onClick={onClickDeleteManager}>Supprimer</Button>
      </DialogActions>    
    </Dialog>
  );
}
