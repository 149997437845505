import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function DeletePackConfirmDialog({
  onClose,
  open,
  onClickDeletePack,
}) {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Supprimer un pack:</DialogTitle>
      <DialogContent>voulez vous vraiment supprimer ce pack ?</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>Annuler</Button>
        <Button variant='contained' onClick={onClickDeletePack}>Supprimer</Button>
      </DialogActions>    
    </Dialog>
  );
}
