import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Button, createTheme, Grid, MenuItem, TextField, ThemeProvider
} from '@mui/material';

export default function AddPackDialog({
  onClose,
  open,
  onClickAddPack,
  errors,
}) {

  const typesPacks = [
    'entreprise',
    'autres démarches'
  ];

  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [maxProducts, setMaxProducts] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    setTitle('');
    setType('');
    setDescription('');
    setMaxProducts('');
    setPrice('');
  }, [open]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} fullWidth>
        <DialogTitle>Ajouter un nouveau pack:</DialogTitle>
        <Grid container spacing={2}>
          {/* title */}
          <Grid item xs={6}>
            <TextField
              id="title"
              label='Titre'
              variant="outlined"
              margin='dense'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={errors && errors.title}
              helperText={errors && errors.title ? errors.title : ''}
              fullWidth
            />
          </Grid>
          {/* type */}
          <Grid item xs={6}>
            <TextField
              id="type"
              select
              label='Type'
              variant="outlined"
              margin='dense'
              value={type}
              onChange={(e) => {setType(e.target.value); console.log(e.target.value);}}
              error={errors && errors.type}
              helperText={errors && errors.type ? errors.type : ''}
              fullWidth
            >
              {typesPacks.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField> 
          </Grid>
        </Grid>
        {/* description */}
        <Grid container>
          <TextField
            id="description"
            label='Déscription'
            variant="outlined"
            margin='dense'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={errors && errors.description}
            helperText={errors && errors.description ? errors.description : ''}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
        <Grid container spacing={2}>
          {/* maxProducts */}
          <Grid item xs={6}>
            <TextField
              id="maxProducts"
              label='Nombre maximum de produits (0 : illimité)'
              type='number'
              inputProps={{min: 0}}
              variant="outlined"
              margin='dense'
              value={maxProducts}
              onChange={(e) => setMaxProducts(e.target.value)}
              error={errors && errors.maxProducts}
              helperText={errors && errors.maxProducts ? errors.maxProducts : ''}
              fullWidth
            />
          </Grid>
          {/* price */}
          <Grid item xs={6}>
            <TextField
              id="price"
              label='Prix'
              type='number'
              inputProps={{min: 0}}
              variant="outlined"
              margin='dense'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              error={errors && errors.price}
              helperText={errors && errors.price ? errors.price : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={onClose}
              fullWidth
            >
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={() => onClickAddPack({
                title,
                type,
                description,
                maxProducts,
                price
              })}
              fullWidth
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '20px',
          paddingTop: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: '5px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        }
      }
    }
  },
});