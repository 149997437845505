import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, createTheme, Grid, TextField, ThemeProvider } from '@mui/material';

export default function AddStatusDialog({
  onClose,
  open,
  onClickAddStatus,
  errors,
}) {
  const [status, setStatus] = useState();

  useEffect(() => {
    setStatus('');
  }, [open]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} fullWidth>
        <DialogTitle>Ajouter un nouveau status:</DialogTitle>
        <Grid container>
          {/* status */}
          <TextField
            id="status"
            label='Status:'
            variant="outlined"
            margin='dense'
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            error={errors && errors.status}
            helperText={errors && errors.status ? errors.status : ''}
            fullWidth
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={onClose}
              fullWidth
            >
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={() => onClickAddStatus({status})}
              fullWidth
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '20px',
          paddingTop: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: '5px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        }
      }
    }
  },
});