import React, { useState } from 'react';
// import './customTextInput.css';

export default function CustomTextInput({
  value, setValue, image, placeholder, type = 'text', min, error
}) {
  const [typeInput, setTypeInput] = useState(type);

  function getInput(type) {
    switch (type) {
    case 'number':
      return (
        <div className='w-full'>
          <input
            type={type}
            value={value}
            min={min}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            className="pl-10 pr-3 py-2 w-full outline-none border-grayv4 border-2 rounded-[10px]  focus:border-primary"
          />
          {error && (<p className='text-red'>{error}</p>)}
        </div>
      );
    case 'date':
      return (
        <div className='w-full'>
          <input
            type={typeInput}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            className="pl-10 pr-3 py-2 w-full outline-none border-grayv4 border-2 rounded-[10px]  focus:border-primary"
            onFocus={() => setTypeInput('date')}
            onBlur={() => setTypeInput('text')}
          />
          {error && (<p className='text-red'>{error}</p>)}
        </div>
      );
    default:
      return (
        <div className='w-full'>
          <input
            type={type}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            className="pl-10 pr-3 py-2 w-full outline-none border-grayv4 border-2 rounded-[10px]  focus:border-primary"
          />
          {error && (<p className='text-red'>{error}</p>)}
        </div>
      );
    }
    
  }

  return (
    <div className='relative flex items-center w-[300px] w-full mb-2 pr-2'>
      <span className={`absolute left-2 ${error && 'top-3'}`}>
        <img src={image} alt="icon" className="w-5  object-cover" />
      </span>
      {getInput(type)}
    </div>
  );
}
