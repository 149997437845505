import React, { useContext } from 'react';
import AdminContext from '../context';

export default function AdminHeader({
  name,
}) {
  const {context} = useContext(AdminContext);
  return (
    <>
      <div className="flex items-center gap-6 py-2 mb-5">
        <div className='rounded-full flex justify-center items-center w-[3rem] h-[3rem]' style={{backgroundColor: context.avatarColor}}>
          <p className='text-white'>{name.charAt(0).toUpperCase()}</p>
        </div>
        <div className='font-inter font-normal font-bold text-2xl'>
          Bonjour, {name}
        </div>
      </div>
    </>
  );
}
