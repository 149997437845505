// BottomBar.js
import React from 'react';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

function BottomBar({ activeTab, handleTabClick }) {
  const tabs = [
    { name: 'dashboard', icon: <DashboardOutlinedIcon fontSize='small'/> },
    { name: 'clients', icon: <GroupOutlinedIcon fontSize='small' /> },
    { name: 'packs', icon: <Inventory2OutlinedIcon fontSize='small' /> },
    { name: 'profile', icon: <ManageAccountsOutlinedIcon fontSize='small' /> },
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white flex justify-around shadow-md p-2 md:hidden z-20">
      {tabs.map(tab => (
        <button
          key={tab.name}
          onClick={() => handleTabClick(tab.name)}
          className="relative flex flex-col items-center p-2 focus:outline-none"
        >
          {tab.icon}
          {activeTab === tab.name && (
            <div className="absolute top-0 right-0 h-2 w-2 bg-primary rounded-full"></div>
          )}
        </button>
      ))}
    </div>
  );
}

export default BottomBar;
