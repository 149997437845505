import React from "react";
import { createHashRouter, Outlet } from "react-router-dom";
import AdminPage from "./utils/middleware";
import Admin from "./utils/components/admin";
import AdminLogin from "./login/login";
import DetailsClient, {loader as clientLoader} from './detailsClient';

export const router = createHashRouter([
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <AdminPage page={<Admin />} />,
      },
      {
        path: 'login',
        element: <AdminPage page={<AdminLogin />} />,
      },
      {
        path: 'client/:clientId',
        element: <AdminPage page={<DetailsClient />} />,
        loader: clientLoader,
      }
    ],
  },
]);
