import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, createTheme, Grid, TextField, ThemeProvider } from '@mui/material';

export default function UpdateManagerDialog({
  onClose,
  open,
  onClickUpdateManager,
  manager,
  errors,
}) {
  const [nom, setNom] = useState(manager.nom);
  const [prenom, setPrenom] = useState(manager.prenom);
  const [email, setEmail] = useState(manager.email);
  const [phone, setPhone] = useState(manager.phone);
  const [birthDate, setBirthDate] = useState(manager.birthDate);

  useEffect(() => {
    setNom(manager.nom);
    setPrenom(manager.prenom);
    setEmail(manager.email);
    setPhone(manager.phone);
    setBirthDate(manager.birthDate);
  }, [manager]);
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} fullWidth>
        <DialogTitle>Modifier un géstionnaire:</DialogTitle>
        <Grid container spacing={2}>
          {/* nom */}
          <Grid item xs={6}>
            <TextField
              id="nom"
              label='Nom'
              variant="outlined"
              margin='dense'
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              error={errors && errors.nom}
              helperText={errors && errors.nom ? errors.nom : ''}
              fullWidth
            />
          </Grid>
          {/* prénom */}
          <Grid item xs={6}>
            <TextField
              id="prenom"
              label='Prénom'
              variant="outlined"
              margin='dense'
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              error={errors && errors.prenom}
              helperText={errors && errors.prenom ? errors.prenom : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* email */}
          <Grid item xs={6}>
            <TextField
              id="email"
              type='email'
              label='Email'
              variant="outlined"
              margin='dense'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errors && errors.email}
              helperText={errors && errors.email ? errors.email : ''}
              fullWidth
            />
          </Grid>
          {/* phone */}
          <Grid item xs={6}>
            <TextField
              id="phone"
              type='tel'
              label='Téléphone'
              variant="outlined"
              margin='dense'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              error={errors && errors.phone}
              helperText={errors && errors.phone ? errors.phone : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        {/* birthDate */}
        <Grid container>
          <TextField
            id="birthDate"
            type='date'
            label='Date de naissance'
            variant="outlined"
            margin='dense'
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            error={errors && errors.birthDate}
            helperText={errors && errors.birthDate ? errors.birthDate : ''}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={onClose}
              fullWidth
            >
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={() => onClickUpdateManager({
                id: manager.id,
                nom,
                prenom,
                email,
                phone,
                birthDate,
              })}
              fullWidth
            >
              Modifier
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '20px',
          paddingTop: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: '5px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        }
      }
    }
  },
});