const FR_LANG = {
  login: {
    connexion: 'Connexion:',
    input: {
      email: 'Connexion:',
      password: 'Mot de passe:',
    },
    stayConnected: 'Rester connecté',
    connect: 'Se connecter'
  },
  sideBar: {
    dashboard: 'Dashboard',
    clients: 'Clients',
    managers: 'Géstionnaires',
    packs: 'Packs',
    status: 'Status',
    profile: 'Profile',
    confirmLogout: 'Êtes-vous sûr de vouloir se déconnecter ?'
  },
  clients: {
    addClientsDialog: {
      addNewClient: 'Ajouter un nouveau client :',
      lastName: 'Nom',
      firstName: 'Prénom',
      email: 'Email',
      phone: 'Téléphone',
      birthDate: 'Date de naissance',
      password: 'Mot de passe',
      confirmPassword: 'Confirmation de mot de passe',
      cancel: 'annuler',
      add: 'ajouter'
    },
    managers: {
      addClientsDialog: {
        addNewClient: 'Ajouter un nouveau client :',
        lastName: 'Nom',
        firstName: 'Prénom',
        email: 'Email',
        phone: 'Téléphone',
        birthDate: 'Date de naissance',
        password: 'Mot de passe',
        confirmPassword: 'Confirmation de mot de passe',
        cancel: 'annuler',
        add: 'ajouter'
      },
    },
    updateClientsDialog: {
      updateClient: 'Modifier un client :',
      lastName: 'Nom',
      firstName: 'Prénom',
      email: 'Email',
      phone: 'Téléphone',
      birthDate: 'Date de naissance',
      password: 'Mot de passe',
      confirmPassword: 'Confirmation de mot de passe',
      cancel: 'annuler',
      update: 'modifier'
    },
    deleteClientConfirmDialog: {
      deleteClient: 'Supprimer un client',
      deleteConfirmationContent: 'voulez vous vraiment supprimer cet utilisateur ?',
      cancel: 'Annuler',
      delete: 'Supprimer',
    },
    clientTable: {
      consult: 'Consulter',
      update: 'Modifier',
      delete: 'Supprimer',
    },
    addClientSuccess: 'client a bien été ajouté',
    updateClientSuccess: 'client a bien été modifié',
    deleteClientSuccess: 'client a bien été supprimé',
    deleteClientError: 'Merci de supprimer tous les produits liés à ce client avant de le supprimer',
    allClients: 'Tous les clients',
    addNewClient: '+ Ajouter un nouveau client',
    searchByName: 'Chercher par Nom',
    emptyClient: 'Aucun client',
    tableHeader: {
      idClient: 'id_client',
      lastName: 'nom',
      firstName: 'prenom',
      email: 'email',
      phone: 'téléphone',
      date: 'Date',
      status: 'Status',
      actions: 'Actions'
    }
  }

};

export default FR_LANG;