import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

export default function CustomSelect({items, defaultValue, value, setValue}) {
  return (
    <div className='relative flex items-center w-[300px] md:w-full mb-2 pr-2'>
      <FormControl
        className='custom-select w-full'
        size='small'
      >
        <Select
          className='outline-none border-grayv4 border-2 rounded-[20px] focus:border-primary'
          defaultValue={defaultValue}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {items.map((item) => {
            return (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>);
          })}
        </Select>
      </FormControl>
    </div>
  );
}

